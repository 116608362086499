%background {
  background-color: #444444;
}

html {
  @extend %background;
  body {
    #root {
      @extend %background;
      .App {
        @extend %background;
        text-align: center;
        color: green;
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        overflow-x: hidden;

        .code {
          text-align: left;
        }
      }
    }
  }
}
